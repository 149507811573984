import { createSlice } from "@reduxjs/toolkit";

const crawlSlice = createSlice({
  name: "crawls",
  initialState: [],
  reducers: {
    setCrawls: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCrawls } = crawlSlice.actions;
export default crawlSlice.reducer;
