import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Alert, Button, Card, Table } from "react-bootstrap";
import axiosClient from "../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinners from "../docs/Spinners";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { startOfMonth, endOfMonth, format } from "date-fns";
import { useDispatch } from "react-redux";
import ConfirmModal from "../components/ConfirmModal";
export default function BlogShow() {
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(false);
  const [notification2, setNotification2] = useState(false);

  const [errors, setErrors] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [articleToDelete, setArticleToDelete] = useState(null);
  useEffect(() => {
    getLinks();
  }, []);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);

  const formattedStartDate = format(dateRange[0].startDate, "yyyy-MM-dd");
  const formattedEndDate = format(dateRange[0].endDate, "yyyy-MM-dd");
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const [client, setClient] = useState({
    id: null,
    name: "",
    url: "",
    email: "",
    client_name: "",
    appellation: "",
    resp_tech: "",
    resp_content: "",
    last_rep_content: "",
    last_rep_pb: "",
    unt_last_rep_content: "",
    unt_last_rep_pb: "",
    articles: "",
  });

  const handleDateChange = (item) => {
    setSelectedDateRange([item.selection]);
  };

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const handleShowModal2 = () => setShowModal2(true);

  const handleCloseModal2 = () => setShowModal2(false);

  const onDeleteArticlePrompt = (article) => {
    setArticleToDelete(article);
    handleShowModal();
  };

  const confirmDeleteArticle = () => {
    if (articleToDelete) {
      //dispatch(setLoading(true));
      axiosClient.delete(`/blog-articles/${articleToDelete.id}`).then(() => {
        setLinks(links.filter((article) => article.id !== articleToDelete.id));
        setNotification(true);
        //dispatch(setLoading(false));
        setTimeout(() => {
          setNotification(false);
        }, 5000);
      });
      handleCloseModal();
    }
  };

  const handleDateConfirm = () => {
    const startDate = format(selectedDateRange[0].startDate, "yyyy-MM-dd");
    const endDate = format(selectedDateRange[0].endDate, "yyyy-MM-dd");
    setShowDatePicker(false); // Adăugați această linie pentru a închide fereastra cu calendarul
    getLinks(startDate, endDate);
  };
  const startOfCurrentMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
  const endOfCurrentMonth = format(endOfMonth(new Date()), "yyyy-MM-dd");
  const getLinks = (
    dateStart = startOfCurrentMonth,
    dateEnd = endOfCurrentMonth
  ) => {
    setLoading(true);
    axiosClient
      .get(`/blog-articles/date/${id}/${dateStart}/${dateEnd}`)
      .then(({ data }) => {
        setLinks(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendMail = () => {
    axiosClient
      .post("/send-content-mail", { client_id: id })
      .then((response) => {
        console.log(response.data.message);
        setEmailSuccess(true); // Actualizați starea pentru succes
        setEmailError(""); // Resetați mesajul de eroare
        setTimeout(() => {
          setEmailSuccess(false); // Resetați notificarea de succes după 5 secunde
        }, 5000);
      })
      .catch((error) => {
        setEmailError("Eroare la trimiterea e-mailului"); // Setează mesajul de eroare
        setEmailSuccess(false); // Asigurați-vă că starea de succes este falsă
      });
    handleCloseModal2();
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      axiosClient
        .get(`/sites/${id}`)
        .then(({ data }) => {
          setClient(data);
          console.log(data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]); // Dependința [id] asigură că efectul se execută doar la schimbarea ID-ului
  const listArticles = () => {
    const totalArticles = client.articles;
    const articleRows = links.map((article, index) => (
      <tr key={article.id} style={{ verticalAlign: "middle" }}>
        <td style={{ textAlign: "right" }}>{index + 1}</td>
        <td>{article.title}</td>
        <td>{article.volume}</td>
        <td>{article.keyword}</td>
        <td>{article.title_approved}</td>
        <td style={{ textAlign: "center" }}>
          <Link to={article.drive}>
            <i className="ri-draft-line fs-3"></i>
          </Link>
        </td>
        <td>{article.articles_approved}</td>
        <td>
          <Link to={article.link}>{article.link}</Link>
        </td>
        <td>{article.date}</td>
        <td style={{ textAlign: "center" }}>
          <Link to={`/blog/${article.id}`}>
            <Button
              style={{ marginRight: "5px", display: "inline-block" }}
              variant="success"
              className="btn-icon"
            >
              <i className="ri-pencil-line"></i>
            </Button>
          </Link>
          <Button
            style={{ marginRight: "5px", display: "inline-block" }}
            variant="danger"
            onClick={() => onDeleteArticlePrompt(article)}
            className="btn-icon"
          >
            <i className="ri-delete-bin-6-line"></i>
          </Button>
        </td>
      </tr>
    ));
    for (let i = links.length; i < totalArticles; i++) {
      articleRows.push(
        <tr
          key={`empty-${i}`}
          style={{ verticalAlign: "middle", textAlign: "right" }}
        >
          <td>{i + 1}</td>
          <td colSpan="9" style={{ textAlign: "center" }}>
            Lipsa articol
          </td>
        </tr>
      );
    }

    return articleRows;
  };

  const isLastRepThisMonth = () => {
    if (!client.unt_last_rep_content) return false;
    const [year, month] = client.unt_last_rep_content.split("-");
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
    return year == currentYear && month == currentMonth;
  };
  return (
    <>
      <Header />

      <ConfirmModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={confirmDeleteArticle}
        title="Confirmare"
        body="Esti sigur ca vrei sa stergi acest articol?"
      />

      <ConfirmModal
        showModal={showModal2}
        handleClose={handleCloseModal2}
        handleConfirm={sendMail}
        title="Confirmare"
        body="Esti sigur ca vrei sa trimiti catre client?"
      />

      <div className="main main-app p-3 p-lg-4">
        {emailSuccess && (
          <Alert
            variant="success"
            className="alert-solid d-flex align-items-center mb-2"
          >
            E-mailul a fost trimis cu succes.
          </Alert>
        )}
        {emailError && (
          <Alert
            variant="danger"
            className="alert-solid d-flex align-items-center mb-2"
          >
            {emailError}
          </Alert>
        )}
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            Articolul a fost sters cu succes.
          </Alert>
        )}

        <br />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <h3>{client.name}</h3>
                <Link to="/">Articole Blog</Link>
              </li>
            </ol>
            <p style={{ color: "#5c5757", fontSize: "12px" }}>
              Ultimul raport trimis: <b>{client.last_rep_content}</b>
            </p>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to={`/sites/show/${id}`}>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                Publishing Plan
              </Button>
            </Link>
            <Button
              variant="secondary"
              className="d-flex align-items-center gap-2"
            >
              Articole Blog
            </Button>
            <Link to={`/sites/seo/${id}`}>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                SEO Onpage
              </Button>
            </Link>

            <Button
              onClick={handleShowModal2}
              variant={isLastRepThisMonth() ? "danger" : "success"}
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-mail-line fs-18 lh-1"></i>Trimite Catre Client
            </Button>

            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setShowDatePicker(true);
              }}
            >
              <Button
                variant=""
                className="btn-white d-flex align-items-center gap-2"
              >
                <i className="ri-calendar-line fs-18 lh-1"></i>Alege data
              </Button>
            </Link>

            <Link to={`/blog/new/${id}`}>
              <Button
                variant="success"
                className="d-flex align-items-center gap-2"
              >
                + Adauga
              </Button>
            </Link>
          </div>
        </div>

        <Card>
          <Card.Body>
            {showDatePicker && (
              <>
                <DateRangePicker
                  onChange={handleDateChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedDateRange}
                  direction="horizontal"
                  rangeColors={["#3d91ff"]}
                />
                <Button
                  style={{ marginTop: "5px", marginLeft: "10px" }}
                  onClick={handleDateConfirm}
                >
                  CONFIRMARE
                </Button>
              </>
            )}

            <Table striped className="mb-0 publish-table">
              <thead>
                <tr>
                  <th style={{ width: "2%" }}>#</th>
                  <th style={{ width: "25%" }}>Titlu Articol</th>
                  <th style={{ width: "10%" }}>Volum Cautari</th>
                  <th style={{ width: "10%" }}>Cuvant Cheie</th>
                  <th style={{ width: "5%" }}>Titlu Aprobat</th>
                  <th style={{ width: "50px" }}>Drive</th>
                  <th style={{ width: "5%" }}>Articol Aprobat</th>
                  <th style={{ width: "30%" }}>Link articol</th>
                  <th style={{ width: "62px" }}>Data</th>
                  <th style={{ width: "110px" }}></th>
                </tr>
              </thead>
              <tbody>{!loading && listArticles()}</tbody>
            </Table>
          </Card.Body>
        </Card>
        {loading && <Spinners />}

        <Footer />
      </div>
    </>
  );
}
