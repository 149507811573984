import { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Col, ListGroup } from "react-bootstrap";
import axiosClient from "../axios-client";

export default function SeoShow() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("idle"); // poate fi: idle, processing, completed, error
  const [sitemapExists, setSitemapExists] = useState(null);
  const [robotsTxtExists, setRobotsTxtExists] = useState(null);
  // const [nonIndexable, setNonIndexable] = useState(null);
  const nonIndexable = false;
  const [client, setClient] = useState({
    id: null,
    name: "",
    url: "",
    email: "",
    client_name: "",
    appellation: "",
    resp_tech: "",
    resp_content: "",
    last_rep_content: "",
    last_rep_pb: "",
    unt_last_rep_content: "",
    unt_last_rep_pb: "",
    articles: "",
  });

  // Fetch client info la montare sau când se schimbă id
  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axiosClient
      .get(`/sites/${id}`)
      .then(({ data }) => {
        setClient(data);
      })
      .catch((err) => {
        console.error("Error fetching client data:", err);
        setError("Eroare la preluarea datelor clientului.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  // Fetch inițial al rezultatelor
  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axiosClient
      .get(`/crawl-results/${id}`)
      .then(({ data }) => {
        if (data.status === "processing") {
          setStatus("processing");
        } else if (data.status === "completed") {
          // Verificăm erori
          const taskResult = data.results;
          const extendedCrawlStatus =
            taskResult?.domain_info?.extended_crawl_status ?? "error";

          if (extendedCrawlStatus !== "no_errors") {
            setError("Nu s-a putut crawla site-ul: " + extendedCrawlStatus);
            setResults(null);
            setStatus("error");
          } else {
            setStatus("completed");
            setResults(taskResult);

            // Setăm valorile suplimentare
            const sitemap = taskResult?.domain_info?.checks?.sitemap ?? false;
            const robotsTxt =
              taskResult?.domain_info?.checks?.robots_txt ?? false;
            const nonIndex = taskResult?.page_metrics?.non_indexable ?? 0;

            setSitemapExists(sitemap === true);
            setRobotsTxtExists(robotsTxt === true);
            //setNonIndexable(nonIndex > 1);
          }
        } else {
          // Dacă nu e nici processing, nici completed, înseamnă că nu avem crawl încă
          setError("Nu a fost rulat încă un crawl.");
          setStatus("error");
        }
      })
      .catch((err) => {
        console.error("Error fetching crawl results:", err);
        setError("Site-ul nu a fost crawlat.");
        setStatus("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  // Polling: dacă status === "processing", verifică la fiecare 5 secunde
  useEffect(() => {
    let interval;
    if (status === "processing" && id) {
      interval = setInterval(() => {
        axiosClient
          .get(`/crawl-results/${id}`)
          .then(({ data }) => {
            if (data.status === "completed") {
              const taskResult = data.results;
              const extendedCrawlStatus =
                taskResult?.domain_info?.extended_crawl_status ?? "error";

              if (extendedCrawlStatus !== "no_errors") {
                setError("Nu s-a putut crawla site-ul: " + extendedCrawlStatus);
                setResults(null);
                setStatus("error");
              } else {
                setStatus("completed");
                setResults(taskResult);

                const sitemap =
                  taskResult?.domain_info?.checks?.sitemap ?? false;
                const robotsTxt =
                  taskResult?.domain_info?.checks?.robots_txt ?? false;
                const nonIndex = taskResult?.page_metrics?.non_indexable ?? 0;

                setSitemapExists(sitemap === true);
                setRobotsTxtExists(robotsTxt === true);
                // setNonIndexable(nonIndex > 1);
              }
              clearInterval(interval);
            }
          })
          .catch((err) => {
            console.error("Error during polling:", err);
            setError("Eroare la polling.");
            setStatus("error");
            clearInterval(interval);
          });
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [status, id]);

  // Pornire crawl la cerere
  const handleStartCrawl = () => {
    if (!id) return;
    setLoading(true);
    const payload = {
      target: id,
      max_pages: 1,
    };

    axiosClient
      .post(`/crawl`, payload)
      .then(({ data }) => {
        console.log("Crawl started:", data);
        setStatus("processing");
        setError(null); // Resetăm erorile
      })
      .catch((error) => {
        console.error("Crawl error:", error);
        setError("Eroare la pornirea crawl-ului.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <h3>{client.name}</h3>
                <Link to="/">Seo Onpage</Link>
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to={`/sites/show/${id}`}>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                Publishing Plan
              </Button>
            </Link>

            <Link to={`/blog/show/${id}`}>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                Articole Blog
              </Button>
            </Link>
            <Button
              variant="secondary"
              className="d-flex align-items-center gap-2"
            >
              Seo Onpage
            </Button>

            <Button
              variant="success"
              className="d-flex align-items-center gap-2"
              onClick={handleStartCrawl}
              disabled={status === "processing"}
            >
              {status === "processing"
                ? "Se crawleaza site-ul..."
                : "Start Crawl"}
            </Button>
          </div>
        </div>

        {error && <div className="alert alert-danger">{error}</div>}
        {loading && <p>Se încarcă...</p>}

        {results && status === "completed" && (
          <Col sm="12" xl="12">
            <Card className="card-one">
              <Card.Body>
                <ListGroup as="ul" className="list-group-one">
                  <ListGroup.Item
                    as="li"
                    className="px-0 d-flex align-items-center gap-2 list-group-item"
                  >
                    <div>
                      <h6 className="mb-0">Sitemap</h6>
                    </div>
                    <div className="ms-auto text-end">
                      <div className="gx-1 gy-2 row">
                        <button
                          type="button"
                          className={`d-flex align-items-center gap-2 btn ${
                            sitemapExists ? "btn-success" : "btn-danger"
                          }`}
                        >
                          {sitemapExists ? "OK" : "NU"}
                        </button>
                      </div>
                    </div>
                  </ListGroup.Item>

                  <ListGroup.Item
                    as="li"
                    className="px-0 d-flex align-items-center gap-2 list-group-item"
                  >
                    <div>
                      <h6 className="mb-0">Robots.txt</h6>
                    </div>
                    <div className="ms-auto text-end">
                      <div className="gx-1 gy-2 row">
                        <button
                          type="button"
                          className={`d-flex align-items-center gap-2 btn ${
                            robotsTxtExists ? "btn-success" : "btn-danger"
                          }`}
                        >
                          {robotsTxtExists ? "OK" : "NU"}
                        </button>
                      </div>
                    </div>
                  </ListGroup.Item>

                  <ListGroup.Item
                    as="li"
                    className="px-0 d-flex align-items-center gap-2 list-group-item"
                  >
                    <div>
                      <h6 className="mb-0">Indexabil</h6>
                    </div>
                    <div className="ms-auto text-end">
                      <div className="gx-1 gy-2 row">
                        <button
                          type="button"
                          className={`d-flex align-items-center gap-2 btn ${
                            nonIndexable ? "btn-danger" : "btn-success"
                          }`}
                        >
                          {nonIndexable ? "NU" : "OK"}
                        </button>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        )}
      </div>
      <Footer />
    </div>
  );
}
